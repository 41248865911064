import { cloneDeep } from "lodash";
import {
    ADD_DROPDOWN_OPTION,
    ADD_VALIDATION_ERROR,
    CALL_FORM_ENDPOINT_START,
    CALL_FORM_ENDPOINT_SUCCESS,
    CHANGE_SERVICEGROUP_SUCCESS,
    CLEAR_DATA,
    GET_FORM_FAIL,
    GET_FORM_START,
    GET_FORM_SUCCESS,
    HANDLE_FIELD_CHANGE,
    HANDLE_FIELD_COPY,
    HANDLE_FIELD_PUSH,
    HANDLE_INDEXED_FIELD_CHANGE,
    REMOVE_VALIDATION_ERROR,
    RESET_VALIDATION,
    SET_DROPDOWN,
    SET_STATE_BY_NAME,
    SET_STATE_NAME_BY_INDEX,
    SUBMIT_FORM_FAIL,
    SUBMIT_FORM_START,
    SUBMIT_FORM_SUCCESS,
    UPLOAD_FINISHED,
    UPLOAD_STARTED
} from "../actions/types";

const initialState = {
    data: {},
    dropdowns: {},
    response: {},
    errors: {},
    uploading: false,
    fetching: false,
    submitted: false,
    endpointFetching: false
};

const formReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_FORM_START:
            return {
                ...state,
                fetching: true
            };
        case GET_FORM_SUCCESS:
            return {
                ...state,
                ...action.payload,
                fetching: false
            };
        case GET_FORM_FAIL:
            return {
                ...state,
                fetching: false,
                data: {},
                dropdowns: {}
            };
        case SET_DROPDOWN:
            return {
                ...state,
                dropdowns: {
                    ...state.dropdowns,
                    [action.payload.name]: action.payload.values
                }
            };
        case ADD_DROPDOWN_OPTION: {
            const { name, value, index } = action.payload;
            let copy = [];
            if (state.dropdowns[name]) {
                copy = [...state.dropdowns[name]];
            }
            if (index !== undefined) {
                copy.splice(index, 0, value);
            }
            else {
                copy.push(value);
            }
            return {
                ...state,
                dropdowns: {
                    ...state.dropdowns,
                    [name]: copy
                }
            };
        }
        case SET_STATE_BY_NAME: {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            };
        }
        case SET_STATE_NAME_BY_INDEX: {
            const { name, value, index } = action.payload;
            let copy;
            if (Array.isArray(state[name])) {
                copy = [...state[name]];
            }
            else {
                copy = state[name] ? { ...state[name] } : null;
            }
            if (copy && copy[index]) {
                copy[index] = value;
                return {
                    ...state,
                    [name]: copy
                };
            }
            return state;
        }
        case CALL_FORM_ENDPOINT_START:
            return {
                ...state,
                endpointFetching: true
            };
        case CALL_FORM_ENDPOINT_SUCCESS: {
            const { data, stateKey } = action.payload;
            if (stateKey.includes("data.")){
                const [, key] = stateKey.split(".");
                return {
                    ...state,
                    endpointFetching: false,
                    data: {
                        ...state.data,
                        [key]: data
                    }
                };
            }
            if (stateKey.includes("dropdowns.")){
                const [, key] = stateKey.split(".");
                return {
                    ...state,
                    endpointFetching: false,
                    dropdowns: {
                        ...state.dropdowns,
                        [key]: data
                    }
                };
            }
            return {
                ...state,
                endpointFetching: false,
                [stateKey]: data
            };
        }
        case SUBMIT_FORM_START:
            return {
                ...state,
                submitted: true,
                fetching: true
            };
        case SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                fetching: false,
                submitted: false,
                response: action.payload
            };
        case SUBMIT_FORM_FAIL:
            return {
                ...state,
                submitted: false,
                fetching: false
            };
        case HANDLE_FIELD_CHANGE: {
            const errors = state.errors;
            let { name: fieldName, value, remove } = action.payload;
            delete errors[fieldName];
			
            const additional = {};
            if (fieldName === "name") {
                // e.g. "John P. Smith";
                const [lastName, ...firstName] = value.trim().split(" ").reverse();
                additional.lastName = lastName; // "Smith"
                additional.firstName = firstName.reverse().join(" "); // "John P."
            }
            if (typeof value === "string"){
                const safeCharactersRegex = /[^a-zA-Z0-9\s.,;:'"!?(){}[\]<>`@#$%^&*_+=|/-]/gu;
                value = value.replace(new RegExp(`^${safeCharactersRegex.source}+`, "gu"), "");
            }
            const data = { ...state.data, [fieldName]: value };
			
            if (remove) delete data[fieldName];
			
            return {
                ...state,
                errors,
                data: {
                    ...data,
                    ...additional
                }
            };
        }
        case HANDLE_FIELD_PUSH: {
            const { name, value } = action.payload;
            const copy = Array.isArray(state.data[name]) ? [...state.data[name]] : [];
            copy.push(value);
            return {
                ...state,
                data: {
                    ...state.data,
                    [name]: copy
                }
            };
        }
        case HANDLE_INDEXED_FIELD_CHANGE: {
            const { name, value, index, remove } = action.payload;
            if (!state.data[name]) {
                return state;
            }

            const copy = Array.isArray(state.data[name])
                ? [...state.data[name]]
                : [];

            if (remove) {
                copy.splice(index, 1);
            }
            else {
                copy[index] = value;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [name]: copy
                }
            };
        }
        case HANDLE_FIELD_COPY: {
            const { fromFieldName, toFieldName } = action.payload;
            if (!fromFieldName || !toFieldName) return state;

            const copy = cloneDeep(state.data[fromFieldName]);
            
            return {
                ...state,
                data: {
                    ...state.data,
                    [toFieldName]: copy
                }
            };
        }
        case RESET_VALIDATION:
            return {
                ...state,
                errors: {}
            };
        case ADD_VALIDATION_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.name]: action.payload.error
                }
            };
        case REMOVE_VALIDATION_ERROR: {
            const errors = state.errors;
            delete errors[action.payload.name];
            return {
                ...state,
                errors
            };
        }
        case UPLOAD_STARTED:
            return {
                ...state,
                uploading: true
            };
        case UPLOAD_FINISHED:
            return {
                ...state,
                uploading: false
            };
        case CLEAR_DATA:
        case CHANGE_SERVICEGROUP_SUCCESS:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default formReducer;
