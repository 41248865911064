import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormControl, FormHelperText } from "@material-ui/core";
import { connect } from "react-redux";
import { handleFieldChange } from "../../actions/formActions";
import { CAPTCHA_SITE_KEY } from "../../common/consts";
import { isLocalhost } from "../../helpers/utils";
const CAPTCHA_DEV_KEY = "6LdFrbYqAAAAAO3-juxPeD-UmEIMYo8TJU_CE094";

const Recaptcha = ({ name = "captcha", handleFieldChange, error, helperText = "" }) => {
    const handleChange = token => {
        handleFieldChange({ name, value: token });
    };

    React.useEffect(() => {
        handleFieldChange({ name, value: "" }); // reset on load
    }, []); // eslint-disable-line

    return (
        <FormControl error={Boolean(error)}>
            <ReCAPTCHA
                sitekey={!isLocalhost() ? CAPTCHA_SITE_KEY : CAPTCHA_DEV_KEY}
                onChange={handleChange}
            />
            <FormHelperText>{error || helperText}</FormHelperText>
        </FormControl>
    );
};

const mapsStateToProps = (state, { name = "captcha" }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(Recaptcha);
