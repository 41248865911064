import { lazy } from "react";
import { AOB, EMSBILL } from "./consts";

const CartCheckout = lazy(() => import("../components/modules/public/cart/CartCheckout"));
const CartDetail = lazy(() => import("../components/modules/public/cart/CartDetail"));
const CartSummary = lazy(() => import("../components/modules/public/cart/CartSummary"));
const DonationLanding = lazy(() => import("../components/modules/public/donation/DonationLanding"));
const EventsCheckinPage = lazy(() => import("../components/modules/public/event/EventsCheckinPage"));
const EventsPage = lazy(() => import("../components/modules/public/event/EventsPageList"));
const EventsPageDetail = lazy(() => import("../components/modules/public/event/EventsPageDetail"));
const EventsPageSeats2 = lazy(() => import("../components/modules/public/event/EventsPageSeats2"));
const ExpressPayConfirmationPage = lazy(() => import("../components/modules/public/expresspay/ConfirmationPage"));
const ExpressPayment = lazy(() => import("../components/modules/public/expresspay/ExpressPayment"));
const FormLandingPage = lazy(() => import("../components/modules/public/forms/FormLandingPage"));
const GroupLookup = lazy(() => import("../components/modules/public/member/group/GroupLookup"));
const GroupPayLanding = lazy(() => import("../components/modules/public/member/group/GroupPayLanding"));
const GroupPaySummary = lazy(() => import("../components/modules/public/member/group/GroupPaySummary"));
const LandingPage = lazy(() => import("../components/modules/public/smsLandingPage/LandingPage"));
const MemberCheckinPage = lazy(() => import("../components/modules/public/member/MemberCheckinPage"));
const MemberLanding = lazy(() => import("../components/modules/public/member/MemberLanding"));
const MemberLookup = lazy(() => import("../components/modules/public/member/MemberLookup"));
const MemberRegister = lazy(() => import("../components/modules/public/member/MemberRegister"));
const POSCartList = lazy(() => import("../components/modules/public/pos/POSCartList"));
const POSLanding = lazy(() => import("../components/modules/public/pos/POSLanding"));
const POSViewCategoryProducts = lazy(() => import("../components/modules/public/pos/POSViewCategoryProducts"));
const PaymentAPIForm = lazy(() => import("../components/modules/public/paymentAPI/PaymentAPIForm"));
const PaymentV2 = lazy(() => import("../components/modules/public/invoice/PaymentV2"));
const PublicAOBForm = lazy(() => import("../components/modules/public/aob/PublicAOBForm"));
const PublicAOBLookup = lazy(() => import("../components/modules/public/aob/PublicAOBLookup"));
const PublicBillLookup = lazy(() => import("../components/modules/public/bill/PublicBillLookup"));
const PublicBillPayment = lazy(() => import("../components/modules/public/bill/PublicBillPayment"));
const ScheduledTransactionPublicPage = lazy(() => import("../components/modules/public/scheduleTransactions/ScheduledTransactionPublicPage"));
const SkywardPage = lazy(() => import("../components/modules/skyward/SkywardPage"));
const StoreLanding = lazy(() => import("../components/modules/public/store/StoreLanding"));
const StoreProductDetail = lazy(() => import("../components/modules/public/store/StoreProductDetail"));
const TicketPagePrint = lazy(() => import("../components/modules/public/ticket/TicketPagePrint"));
const TicketsDigital = lazy(() => import("../components/modules/public/ticket/TicketsDigital"));
const UnsubscribePage = lazy(() => import("../components/modules/public/general/UnsubscribePage"));
const PublicSurveyForm = lazy(() => import("../components/modules/public/survey/PublicSurveyForm"));

/* Public routes should also be added to client\public\php\routes.php which in order for index meta data to match */
const publicRoutes = [
    { path: "/assignBenefits/:systemKey", component: PublicAOBLookup, props: { targType: AOB } },
    { path: "/assignBenefits/:systemKey/:serviceGroupID/:billNumber/:entityCode/:serviceDate", component: PublicAOBForm, props: { targType: AOB } },
    { path: "/assignBenefits/:systemKey/:serviceGroupID/:billNumber/:serviceDate", component: PublicAOBForm, props: { targType: AOB } },
    { path: "/emsbillpay/:systemKey?/:billID?", component: PublicBillLookup, props: { serviceGroupID: 5, targType: EMSBILL } },
    { path: "/expresspay/:agencyNameKey", component: ExpressPayment },
    { path: "/expresspay/confirmation/:agencyNameKey/:terminalID/:transID", component: ExpressPayConfirmationPage },
    { path: "/p/:agencyNameKey/cart", component: CartDetail },
    { path: "/p/:agencyNameKey/cart/checkout", component: CartCheckout },
    { path: "/p/:agencyNameKey/cart/summary/:purchaseKey/:purchaseID", component: CartSummary },
    { path: "/p/:agencyNameKey/donate", component: DonationLanding },
    { path: "/p/:agencyNameKey/donate/lookup", component: MemberLookup },
    { path: "/p/:agencyNameKey/donate/register", component: MemberRegister },
    { path: "/p/:agencyNameKey/events", component: EventsPage },
    { path: "/p/:agencyNameKey/events/:eventID/checkin", component: EventsCheckinPage },
    { path: "/p/:agencyNameKey/events/:eventID/seats/:offerID?", component: EventsPageSeats2 },
    { path: "/p/:agencyNameKey/events/:eventID/view", component: EventsPageDetail },
    { path: "/p/:agencyNameKey/forms/:formID", component: FormLandingPage },
    { path: "/p/:agencyNameKey/groupPay", component: GroupPayLanding },
    { path: "/p/:agencyNameKey/groupPay/lookup", component: GroupLookup },
    { path: "/p/:agencyNameKey/groupPay/summary/:receiptKey", component: GroupPaySummary },
    { path: "/p/:agencyNameKey/member/checkin", component: MemberCheckinPage },
    { path: "/p/:agencyNameKey/membership", component: MemberLanding },
    { path: "/p/:agencyNameKey/membership/lookup", component: MemberLookup },
    { path: "/p/:agencyNameKey/membership/register", component: MemberRegister },
    { path: "/p/:agencyNameKey/payments/:queueRef/:queueID", component: PaymentAPIForm },
    { path: "/p/:agencyNameKey/pos/:cartID", component: POSLanding },
    { path: "/p/:agencyNameKey/pos/:cartID/:categoryID", component: POSViewCategoryProducts, exact: true },
    { path: "/p/:agencyNameKey/pos/carts", component: POSCartList, exact: true },
    { path: "/p/:agencyNameKey/scheduledTransaction/:scheduleID/:recID/:tblID", component: ScheduledTransactionPublicPage },
    { path: "/p/:agencyNameKey/skyward", component: SkywardPage, exact: true },
    { path: "/p/:agencyNameKey/store", component: StoreLanding },
    { path: "/p/:agencyNameKey/store/:productID", component: StoreProductDetail },
    { path: "/p/:agencyNameKey/store/category/:categoryID", component: StoreLanding },
    { path: "/p/:agencyNameKey/ticket/:attendeeKey/:attendeeID/print", component: TicketPagePrint },
    { path: "/p/:agencyNameKey/ticket/:attendeeKey/:attendeeID/print/all", component: TicketPagePrint, props: { all: true } },
    { path: "/p/:agencyNameKey/ticket/a/:attendeeKey/:attendeeID", component: TicketsDigital },
    { path: "/p/:agencyNameKey/ticket/e/:purchaseKey/:purchaseID/:eventID", component: TicketsDigital },
    { path: "/p/:agencyNameKey/unsubscribe/:email?", component: UnsubscribePage },
    { path: "/p/survey/:surveyID/:responseID", component: PublicSurveyForm },
    { path: "/payment/:invoiceID/:invoiceRef", component: PaymentV2 },
    { path: "/paymybill/:systemKey?/:billID?", component: PublicBillLookup, props: { targType: EMSBILL } },
    { path: "/paymybill/:systemKey?/payment/:billID?", component: PublicBillPayment, props: { targType: EMSBILL } },
    { path: "/sms/:systemKey/:type/:billNumber", component: LandingPage }
];
	
export default publicRoutes;
