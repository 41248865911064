import React from "react";
import { connect } from "react-redux";
import Rating from "./base/Rating";
import { handleFieldChange } from "../../actions/formActions";
const RatingField = ({ name, handleFieldChange, onChange, max, ...allOtherProps }) => {
    const handleChange = e => {
        handleFieldChange({ name, value: Number(e.target.value) });
        if (typeof onChange === "function") onChange(e);
    };
    return <Rating {...allOtherProps} name={name} onChange={handleChange} max={max} />;
};
const mapStateToProps = (state, { name }) => {
    return {
        value: Number(state.form.data[name]),
        error: state.form.errors[name]
    };
};
const mapDispatchToProps = { handleFieldChange };

export default connect(mapStateToProps, mapDispatchToProps)(RatingField);
