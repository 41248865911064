import React from "react";
import { connect } from "react-redux";
import { Multiselect } from "./base";
import { handleFieldChange } from "../../actions/formActions";
import { isEmpty, isPlainObject } from "lodash";

const MultiselectField = ({ name, handleFieldChange, optVal, onChange, options = [], value, useFirst, defValue, ...allOtherProps }) => {
    React.useEffect(() => {
        if (!value || (isPlainObject(value) && isEmpty(value))) {
            if (defValue) {
                let val = defValue;
                if (["string", "number"].includes(typeof defValue)){
                    val = { [defValue]: true };
                }
                handleFieldChange({ name, value: val });
            }
            else if (useFirst && options.length) {
                handleFieldChange({ name, value: { [options[0][optVal]]: true } });
            }
        }
    }, [name, value, options, optVal, defValue, useFirst]); // eslint-disable-line
	
    const handleChange = e => {
        let val = "";
        if (e.target.value && Array.isArray(e.target.value)){
            val = e.target.value.reduce((rtn, val) => {
                rtn[val[optVal]] = true;
                return rtn;
            }, {});
        }
        handleFieldChange({ name, value: val });
        if (typeof onChange === "function") onChange(e);
    };
	
    return <Multiselect {...allOtherProps} name={name} value={value} optVal={optVal} onChange={handleChange} options={options} />;
};

const mapsStateToProps = (state, { name, ...ownProps }) => {
    return {
        value: state.form.data[name] ? state.form.data[name] : {},
        options: "options" in ownProps ? ownProps.options : state.form.dropdowns[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(MultiselectField);
