import { isLocalhost } from "../helpers/utils";

const isDev = isLocalhost();
const CLOUDINARY_NAME = isDev ? "eservicesdev" : "eservicestech";

export const ACCUMED_SGID = "1155";
export const ADMIN = "ADMIN";
export const ADDRESS = "ADDRESS";
export const AOB = "AOB";
export const AOB_LOOKUP = "AOB_LOOKUP";
export const AOB_PUBLIC = "AOB_PUBLIC";
export const APP_TITLE = "eServices Technology";
export const APP_DESCRIPTION = `eServices Technology specializes in payment processing solutions offering an intuitive SAAS platform for bill payments, donations, events, ticketing, membership management and more`;
export const APP_IMAGE = "https://admin.eservicestech.com/background.jpg";
export const APPLE_WALLET_PASS_CREATION_ERROR = "APPLE_WALLET_PASS_CREATION_ERROR";
export const ATTENDEE = "ATTENDEE";
export const BILLING = "BILLING";
export const BILL_LOOKUP = "BILL_LOOKUP";
export const BILL_PAYMENT = "BILL_PAYMENT";
export const BILL_PAYMENT_BATCH = "BILL_PAYMENT_BATCH";
export const BILLING_SCHED = "BILLING_SCHED";
export const CALL_LOG = "CALL_LOG";
export const CAMPAIGNS = "CAMPAIGNS";
export const CAPTCHA_SITE_KEY = "6LfSuoccAAAAAN5hcASf8nCOD6I850IysSqfN-DC"; // live
export const CAPTURE = "CAPTURE";
export const CART = "CART";
export const CLOUDINARY_URL = `https://res.cloudinary.com/${CLOUDINARY_NAME}`;
export const CONFIG = "CONFIG";
export const CONTACT = "CONTACT";
export const CUSTOM_FIELD = "CUSTOM_FIELD";
export const CUSTOM_FIELD_LIST = "CUSTOM_FIELD_LIST";
export const DASHBOARD = "DASHBOARD";
export const DATA_DOG_CLIENT_TOKEN = "pub859540f989bef0a08c06c542ac7457c0";
export const DATA_DOG_SITE = "us5.datadoghq.com";
export const DOCUMENTS = "DOCUMENTS";
export const DOMAIN = isDev ? "http://localhost:3000" : "https://admin.eservicestech.com";
export const DOMAIN_API = isDev ? "http://localhost:8000" : "https://admin.eservicestech.com";
export const DONATE = "DONATE";
export const DONATION = "DONATION";
export const EMSBILL = "EMSBILL";
export const ESERVICES_SGID = 1026;
export const EVENT_OFFER = "EVENT_OFFER";
export const EVENT_SETTING = "EVENT_SETTING";
export const EVENT_TYPE = "EVENT_TYPE";
export const EVENT = "EVENT";
export const SPONSORSHIP = "SPONSORSHIP";
export const EVENT_PUBLIC = "EVENT_PUBLIC";
export const eventStatus = {
    CANCELLED: "Cancelled",
    POSTPONED: "Postponed",
    RESCHEDULED: "Rescheduled",
    SCHEDULED: "Scheduled",
    PENDING: "Pending"
};
export const EXPIRED_MFA_CODE = "Two-Factor Code Expired";
export const EXPRESS_PAY = "EXPRESS_PAY";
export const FORM_DOES_NOT_EXIST = "Form does not exist";
export const GIVING = "GIVING";
export const HELP = "HELP";
export const INQUIRE = "INQUIRE";
export const INVALID_EMAIL_OR_PASSWORD = "Invalid email or password";
export const INVALID_MFA_CODE = "Invalid Two-Factor Code";
export const INVOICE = "INVOICE";
export const INVOICE_ITEM = "INVOICE_ITEM";
export const INVOICE_PAYMENT = "INVOICE_PAYMENT";
export const LIMITED = "Limited";
export const MEMBER = "MEMBER";
export const MEMBER_DISCOUNT = "MEMBER_DISCOUNT";
export const MEMBER_DEPENDENT = "MEMBER_DEPENDENT";
export const MEMBER_GROUP = "MEMBER_GROUP";
export const MEMBER_GROUP_SETTING = "MEMBER_GROUP_SETTING";
export const MEMBER_INSURANCE = "MEMBER_INSURANCE";
export const MEMBER_NOTE = "MEMBER_NOTE";
export const MEMBER_PUBLIC = "MEMBER_PUBLIC";
export const MEMBERS_DONATION_SCHED = "MEMBERS_DONATION_SCHED";
export const MEMBERS_MEMBERSHIP_SCHED = "MEMBERS_MEMBERSHIP_SCHED";
export const MEMBERSHIP = "MEMBERSHIP";
export const MEMBERSHIP_TYPE = "MEMBERSHIP_TYPE";
export const MESSAGES = "MESSAGES";
export const MID = "MID";
export const MISSING_TERMINAL_MID_ERROR = "UNABLE TO FIND A TERMINAL MID";
export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
export const NOTIFICATION_TYPES = {
    SURVEY_NOTIFICATION: 1,
    BILLING_NOTIFICATION: 2,
    AOB_NOTIFICATION: 3,
    MEMBERSHIP_RENEWAL_NOTIFICATION: 4
};
export const OFFICE = "OFFICE";
export const PAYMENT_ADD = "PAYMENT_ADD";
export const PAYMENT_SCHED = "PAYMENT_SCHED";
export const PRINT = "PRINT";
export const POS = "POS";
export const POS_CATEGORIES = "POS_CATEGORIES";
export const POS_ORDER_TYPES = {
    "In-Store": 1,
    Takeout: 2,
    Delivery: 3
};
export const POS_PAYMENT_TYPES = {
    Cash: 1,
    Card: 2,
    Terminal: 3
};
export const POS_PRODUCTS = "POS_PRODUCTS";
export const POS_SIZES = "POS_SIZES";
export const POS_TRANSACTION = "POS_TRANSACTION";
export const POS_VARIATION = "POS_VARIATION";
export const POSTING = "POSTING";
export const REFUND = "REFUND";
export const REPORTS = "REPORTS";
export const SEATS = "SEATS";
export const SEAT_SIZE = 20;
export const SKYWARD = "SKYWARD";
export const STORE = "STORE";
export const SURVEY = "SURVEY";
export const TERMINAL = "TERMINAL";
export const TICKET = "TICKET";
export const TICKET_COMMENT = "TICKET_COMMENT";
export const USER = "USER";
export const VIRTUAL_TERMINAL = "VIRTUAL TERMINAL";
export const VOID = "VOID";
export const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const tblRef = {
    serviceGroup: 1,
    attendee: 2,
    offer: 3,
    event: 4,
    invoice: 7,
    invoiceItem: 8,
    invoicePayment: 9,
    contact: 10,
    virtualTransactions: 11,
    bill: 12,
    user: 13,
    office: 14,
    aob: 15,
    eventTypes: 16,
    transaction: 18,
    purchase: 19,
    member: 20,
    memberGroup: 21,
    transAPI: 22,
    checkBatch: 23,
    address: 24,
    callLog: 25
};

export const surveyQuestionTypes = {
    SHORT_ANSWER: 1,
    LONG_ANSWER: 2,
    MULTIPLE_CHOICE: 3,
    CHECKBOXES: 4,
    RATING: 5,
    NET_PROMOTER_SCORE: 6,
    YES_NO: 7,
    PHONE_NUMBER: 8
};
