import React from "react";
import { Rating as MuiRating } from "@material-ui/lab";
import { Box, FormHelperText, FormLabel, makeStyles, Typography } from "@material-ui/core";
const useClasses = makeStyles(theme => ({
    outerBox: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "row"
        }
    },
    rating: {
        margin: "5px 0",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "row"
        }
    }
}));
const Rating = ({ name, value, helperText, error, onChange, ...allOtherProps }) => {
    const {
        classes,
        defaultValue,
        disabled,
        emptyIcon,
        emptyLabelText,
        getLabelText,
        icon,
        IconContainerComponent,
        max,
        precision = 1,
        readOnly,
        size,
        label,
        startAdornment,
        endAdornment
    } = allOtherProps;
    const eligibleProps = {
        classes,
        defaultValue,
        disabled,
        emptyIcon,
        emptyLabelText,
        getLabelText,
        icon,
        IconContainerComponent,
        max,
        precision,
        readOnly,
        size
    };
    const classes2 = useClasses();
    return (
        <Box className={classes2.outerBox}>
            {startAdornment && <Box>{startAdornment}</Box>}
            <Box component="fieldset" borderColor="transparent">
                {label && <FormLabel component="legend">{label}</FormLabel>}
                <MuiRating
                    {...eligibleProps}
                    name={name}
                    value={value || 0}
                    onChange={onChange}
                    className={classes2.rating}
                />
                {error && <Typography variant="body2" color="error" display="block">{error}</Typography>}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Box>
            {endAdornment && <Box>{endAdornment}</Box>}
        </Box>
    );
};
export default React.memo(Rating);
